import React, { useRef, useState} from "react";
import { HALF_ROTATION_RANGE, ROTATION_RANGE } from './constants';
import { motion } from "framer-motion";
import png2 from '../Home/picH/set1 1.svg';
import png3 from '../Home/picH/set2 1.svg';


const useHome = () => {

    const abouthRef = useRef(null);
    const scrollToElement = () => abouthRef?.current && abouthRef?.current?.scrollIntoView({ behavior: 'smooth' });


    const TiltCard = () => {
        const ref = useRef(null);
      
        const [rotateX, setRotateX] = useState(0);
        const [rotateY, setRotateY] = useState(0);
      
        const handleMouseMove = (e) => {
          if (!ref.current) return;
      
          const rect = ref.current.getBoundingClientRect();
      
          const width = rect.width;
          const height = rect.height;
      
          const mouseX = (e.clientX - rect.left) * ROTATION_RANGE;
          const mouseY = (e.clientY - rect.top) * ROTATION_RANGE;
      
          const rY = mouseX / width - HALF_ROTATION_RANGE;
          const rX = (mouseY / height - HALF_ROTATION_RANGE) * -1;
      
          setRotateX(rX);
          setRotateY(rY);
        };
      
        const handleMouseLeave = () => {
          if (!ref.current) return;
          setRotateX(0);
          setRotateY(0);
        };
      
      
        return (
          <motion.div
            ref={ref}
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeave}
            style={{
              transformStyle: "preserve-3d",
            }}
            animate={{
              rotateX,
              rotateY,
            }}
            className="relative h-96 w-72 rounded-xl bg-gradient-to-br from-indigo-300 to-violet-300"
          >
            <div
              style={{
                transform: "translateZ(75px)",
                transformStyle: "preserve-3d",
              }}
              className="absolute inset-4 grid place-content-center rounded-xl bg-white shadow-lg"
            >
              <p
                style={{
                  transform: "translateZ(50px)",
                }}
                className="text-center text-2xl font-bold"
              >
              </p>
              <img className='nkar2' src={png3} alt="" />
            </div>
          </motion.div>
        );
    };
      
    const TiltCard1 = () => {
    const ref = useRef(null);
    
    const [rotateX, setRotateX] = useState(0);
    const [rotateY, setRotateY] = useState(0);
    
    const handleMouseMove = (e) => {
        if (!ref.current) return;
    
        const rect = ref.current.getBoundingClientRect();
    
        const width = rect.width;
        const height = rect.height;
    
        const mouseX = (e.clientX - rect.left) * ROTATION_RANGE;
        const mouseY = (e.clientY - rect.top) * ROTATION_RANGE;
    
        const rY = mouseX / width - HALF_ROTATION_RANGE;
        const rX = (mouseY / height - HALF_ROTATION_RANGE) * -1;
    
        setRotateX(rX);
        setRotateY(rY);
    };
    
    const handleMouseLeave = () => {
        if (!ref.current) return;
        setRotateX(0);
        setRotateY(0);
    };
    
    
    return (
        <motion.div
        ref={ref}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
        style={{
            transformStyle: "preserve-3d",
        }}
        animate={{
            rotateX,
            rotateY,
        }}
        className="relative h-96 w-72 rounded-xl bg-gradient-to-br from-indigo-300 to-violet-300"
        >
        <div
            style={{
            transform: "translateZ(75px)",
            transformStyle: "preserve-3d",
            }}
            className="absolute inset-4 grid place-content-center rounded-xl bg-white shadow-lg"
        >
            {/* <FiMousePointer
            style={{
                transform: "translateZ(75px)",
            }}
            className="mx-auto text-4xl"
            /> */}
            <p
            style={{
                transform: "translateZ(50px)",
            }}
            className="text-center text-2xl font-bold"
            >
            </p>
            <img src={png2}  className='nkar1' alt="" />
        </div>
        </motion.div>
    );
    };
      
    return {
        scrollToElement,
        abouthRef,
        TiltCard,
        TiltCard1, 
    }
}

export default useHome;